function initFilterGreen() {
    if (document.getElementById("green-filter")) {
        setTimeout(() => { }, 1);
        document.getElementById("green-filter").addEventListener("click", (event) => {
            const notGreenElements = document.querySelectorAll('[data-is-green="false"]');
            if (event.target.checked) {
                notGreenElements.forEach(element => {
                    element.style.display = "none";
                });
            } else {
                notGreenElements.forEach(element => {
                    element.style.display = "block";
                });
            }

        });
    }
}

export { initFilterGreen };